import React, { Component } from 'react';

import Page from '../components/Page';
import Container from '../components/Container';
import { Box } from 'rebass';
import Checkout from '../components/Checkout';
import AboutBody from '../components/AboutBody';
import { graphql } from 'gatsby';

class confirmation extends Component {
  render() {
    return (
      <div>
        <Page>
          <Container>
            <AboutBody content={this.props.data.contentfulPages} />
          </Container>
        </Page>
      </div>
    );
  }
}

export default confirmation;

export const query = graphql`
  query SuccessPagesQuery {
    contentfulPages(title: { eq: "Success" }) {
      id
      header
      bodyTitle
      body {
        body
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`;
